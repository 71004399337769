import React             from 'react';
import { Box, useTheme } from '@chakra-ui/react';

const FramedImage = ({ children, borderOnGatsbyImage, sx, ...props }) => {
  const theme = useTheme();
  const color = theme.colorMode === 'light' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.7)';
  if (borderOnGatsbyImage) {
    return (
      <Box sx={{
        '.gatsby-image-wrapper': {
          border: `5px solid ${color}`
        },
        ...sx
      }} {...props}>
        {children}
      </Box>
    )
  } else {
    return (
      <Box border={`5px solid ${color}`} {...sx} {...props}>
        {children}
      </Box>
    )
  }
};

export default FramedImage;